import { useAddMediaMutation } from "store/api/media"

const useUploadImage = () => {
    const [uploadMedia, res] = useAddMediaMutation()
    const upload = async (file:File) => {
        const formData = new FormData()
        formData.append('file', file)
        const res = (await uploadMedia(formData)) as {
            data:{
                data: {
                    source_url: string
                }
            }
        }
        return res?.data?.data?.source_url
    }
    return {
        upload,
        isLoading:res.isLoading,
    }
}

export {useUploadImage}