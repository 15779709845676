import { TEN_YEARS } from 'constants/api';
import {baseApi} from './baseApi';

const BASE = 'products'

const productsApi = baseApi.injectEndpoints ({
  endpoints: builder => ({
    getProducts: builder.query ({
      query: collegeId => `${BASE}?school=${collegeId}&status=1`,
      keepUnusedDataFor: TEN_YEARS,
      transformResponse: res => res.data
    }),
    getProduct: builder.query ({
      query: id => `${BASE}/${id}?status=1`,
      keepUnusedDataFor: TEN_YEARS,
      transformResponse: res => res.data,
    }),
    getVariations: builder.query ({
      query: id => `${BASE}?parent=${id}&status=1`,
      keepUnusedDataFor: TEN_YEARS,
      transformResponse: res => res.data
    }),
  }),
});

export const {
  useGetProductQuery,
  useGetProductsQuery,
  useGetVariationsQuery,
  useLazyGetVariationsQuery,
} = productsApi;
