import React, { useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Student } from "data/data";
import { useUser } from "store/utils/hooks";
import { StudentSection } from "./AccountPage";
import { useUpdateBillingMutation } from "store/api/customersApi";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices";
import { v1 as uuid } from "uuid";

const AccountStudents = () => {
  const [students, setStudents] = useState<Student[]>([]);
  const [update, res] = useUpdateBillingMutation();
  const dispatch = useDispatch();
  const user = useUser();
  useEffect(() => {
    setStudents(user?.students || []);
  }, [user]);
  useEffect(() => {
    if (res.isError) {
      toast.error(res.error.data.message);
    } else if (res.isSuccess) {
      dispatch(setUser(res.data.data));
      toast.success("Usuario actualizado");
    }
  }, [res]);
  function onSubmit() {
    update({
      ...user,
      ...{schools:undefined},
      students: students.map((it) => {
        if (typeof it.id === "string") {
          delete it.id;
        }
        return it;
      }),
    });
  }
  return (
    <CommonLayout>
      <div className="space-y-10 sm:space-y-12">
        {/* HEADING */}
        <h2 className="text-2xl sm:text-3xl font-semibold">Mis estudiantes</h2>
        {/* ----- */}
        <div className="pt-2 max-sm:space-y-4 col-span-2 sm:grid-cols-2 lg:grid-cols-3 sm:grid sm:gap-4 lg:gap-8">
          {students?.map((it) => {
            return (
              <StudentSection
                onDelete={(removed) =>
                  setStudents((prev) => prev.filter(({ id }) => id !== removed))
                }
                item={it}
                setArray={setStudents}
              />
            );
          })}
          <div>
            <ButtonSecondary
              disabled={res.isLoading}
              fontSize="text-sm"
              onClick={() =>
                setStudents((prev) => [
                  ...prev,
                  {
                    id: uuid(),
                  },
                ])
              }
              type="button"
              // className="col-span-2"
            >
              Agregar Estudiante
            </ButtonSecondary>
          </div>
        </div>
        <div className="pt-2">
          <ButtonPrimary
            loading={res.isLoading}
            type="submit"
            onClick={onSubmit}
            className="col-span-2"
          >
            Guardar
          </ButtonPrimary>
        </div>
      </div>
    </CommonLayout>
  );
};

export default AccountStudents;
