import Heading from 'components/Heading/Heading'
import { RichTextFormat } from 'containers/ProductDetailPage/AccordionInfo'
import React from 'react'
import { useGetPoliticsQuery } from 'store/api/politicsApi'
import parse from 'html-react-parser';
import { _renderLoading } from 'components/SectionSliderProductCard';


const CommonPoliticsPage = ({politic, title}:{politic:string, title:string}) => {
    const {data, isLoading} = useGetPoliticsQuery(undefined) 
  return (
    <div className='container my-16'>
        <Heading desc={""}>
            {title}
        </Heading>
        {
          isLoading
          ?_renderLoading()
          :<RichTextFormat>
            {(data && data[politic]) ? parse(data[politic]) : "Aún no hay nada que mostrar"}
          </RichTextFormat>
        }
    </div>
  )
}
export default CommonPoliticsPage;