import { OrderStatus } from "utils/get-order-status";
import { MetaData, User } from "./data";

//  ######  CustomLink  ######## //
export interface CustomLink {
  label: string;
  href?: string;
  targetBlank?: boolean;
  className?: string;
}

export enum SEND_TYPE {
  COLLEGE = "school",
  DIRECTION = "direction",
  FREE = "free",
}

export type SendType = `${SEND_TYPE}`;

export type CartItem = {
  name: string;
  slug: string;
  image: string;
  price: number;
  attributes: { [key: string]: string };
  id: number;
  parent_id: number;
  itemTotal: number;
  quantity: number;
  isOutOfStock?: boolean;
  pivot_parent_id?: number;
  limit_store?: boolean;
  maxCartAmount?: number;
};

export type TwMainColor =
  | "pink"
  | "green"
  | "yellow"
  | "red"
  | "indigo"
  | "blue"
  | "purple"
  | "gray";

//
export type Product = {
  id: number;
  price: number;
  category: string;
  description: string;
  image: string | null;
  limit_store?: boolean;
  store_without_stock?: boolean;
  pivot?: {
    parent_id: number;
  };
  rating: {
    rate: number;
    count: number;
  };
  name: string;
  slug: string;
  type: string;
  status: string;
  short_description: string;
  sale_price: number;
  total_sales: number;
  stock_quantity: number | null;
  parent_id: number;
  size_guide: string;
  instructions: string;
  categories: {
    id: number;
    name: string;
    slug: string;
  }[];
  images:
  | null
  | {
    id: number;
    src: string;
  }[];
  attributes: {
    id: number;
    name: string;
    slug: string;
    options: string[];
    option: string;
  }[];
  variations: number[];
  composed_name?: string;
};

export type College = {
  id: number;
  logo: string;
  image1: string;
  image2: string;
  image3: string;
  code: string;
  name: string;
  url: string;
  liquidacion: number;
  estado: boolean;
};

export type SalesInADate = {
  sales: number;
  orders: number;
  items: number;
  tax: number;
  shipping: number;
  discount: number;
  customers: number;
};

export type SalesReport = {
  total_sales: number;
  net_sales: number;
  average_sales: number;
  total_orders: number;
  total_items: number;
  total_tax: number;
  total_shipping: number;
  total_refunds: number;
  total_discount: number;
  totals_grouped_by: string;
  totals: object;
  total_customers: number;
};

type Billing = {
  first_name: number;
  last_name: number;
  company: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  postcode: number;
  country: string;
  email: string;
  phone: number;
};

export type LineItem = {
  id: number;
  name: string;
  product_id: number;
  amount: number;
  subtotal: number;
  total: number;
  total_tax: number;
  sku: string;
  price: number;
  pending_devolution?: number;
  image: string;
  parent_name: number | null;
  product: Product;
  devolution: number;
  partial_send: number;
  attributes: Attribute[];
};

type Refund = {
  id: number;
  reasonOption: string;
  refundImage: string;
  sendPrice: number;
  reason: string | null;
};

export type Order = {
  send_price: number;
  discount: number;
  school_id: number;
  status: OrderStatus;
  can_refund: boolean;
  ref: string;
  user_id: number;
  updated_at: number;
  created_at: number;
  id: number;
  total_price: number;
  product_price: number;
  line_items: LineItem[];
  refunded: Refund;
  billing?: User;
  is_available_to_refund: boolean;
  order: string;
  send_type: SendType;
  refunded_at: string;
  bill_number: string;
  refunded_bill_number: string;
  refunds_map: {
    [key: number]: {
      quantity: number;
      product_id: number;
      id: number;
      variation_id: number;
    };
  };
};

export type Category = {
  id: number;
  name: string;
  slug: string;
  parent: number;
  description: string;
  display: string;
  image?: string;
  menu_order: 0;
  count: 1;
};

export type Attribute = {
  id: number;
  name: string;
  slug: string;
  type: string;
  order_by: string;
  has_archives: boolean;
};
