import { College, CollegeProduct, User } from "data/data";
import { Order } from "data/types";
import { useSelector } from "react-redux";
import { useGetOrdersQuery } from "store/api/ordersApi";
import { useGetProductsQuery } from "store/api/productsApi";
import { RootStore } from "store/store";

export const useCollege = (): College => {
  const { college } = useSelector((state: RootStore) => state.college);
  return college;
};

export const useProducts = (withLoading: boolean = false): CollegeProduct[] | Array<CollegeProduct[] | boolean> => {
  const college = useCollege();
  const { data, isLoading } = useGetProductsQuery(college.id);
  if (withLoading) {
    return [data, isLoading];
  }
  return data as CollegeProduct[];
};

export const useUser = (): User => {
  const { user } = useSelector((state: RootStore) => state.user);
  return user;
};

export const useOrders = (id?:number):{
  data:(Order[] | Order | undefined)
  isLoading:boolean
} => {
  const { user } = useSelector((state: RootStore) => state.user);
  const {data,isLoading} = useGetOrdersQuery(user?.id)
  if(id){
    return {data:(data?.data as Order[])?.find(it=>it.id === id), isLoading}
  }
  return {data,isLoading}
}
