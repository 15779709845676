import React, { FC } from "react";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TabFilters from "components/TabFilters";
import { Transition } from "@headlessui/react";
import { useGetCategoriesQuery } from "store/api/categories";
import { useSearchParams } from "react-router-dom";
import Sort from "./Sort";
import { Category } from "data/data";
import { useProducts } from "store/utils/hooks";

export interface HeaderFilterSearchPageProps {
  className?: string;
}

export const Categories = () => {
  const [params, setParams] = useSearchParams()
  const tabActive = params.get('category') && Number(params.get('category'))
  const [isOpen, setIsOpen] = React.useState(true);
  const [products, isLoading] = useProducts(true);
  const categories:(Category[] | undefined) = products?.reduce((acc,curr)=>{
    curr?.categories?.forEach((it)=>{
      if(!acc.some((it2)=>it2.id === it.id)){
        acc.push(it)
      }
    })
    return acc
  },[])
  return <>
    <NavItem
      isActive={!tabActive}
      onClick={() => setParams(()=>{
        const curr = Object.fromEntries(params)
        delete curr.category
        return curr
      })}
    >
      Todas las categorías
    </NavItem>
    {categories?.map(
      (item, index) => (
        <NavItem
          key={index}
          isActive={tabActive === item.id}
          onClick={() => setParams({...Object.fromEntries(params), category:item.id})}
        >
          {item.name}
        </NavItem>
      )
    )}
  </>
}

const HeaderFilterSearchPage: FC<HeaderFilterSearchPageProps> = ({
  className = "mb-12",
}) => {

  return (
    <div className={`flex flex-col relative ${className}`}>
      <div className="flex md:justify-end flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative md:hidden flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          <Categories/>
        </Nav>
        <span className="block flex-shrink-0 text-right">
          <Sort/>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilterSearchPage;
