import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Student, WooCommerceError } from "data/data";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ContactInfo from "./ContactInfo";
import ShippingAddress from "./ShippingAddress";
import { CartItem, SEND_TYPE } from "data/types";
import { useCart } from "context/cart/cart.context";
import { useCollege, useUser } from "store/utils/hooks";
import { useForm } from "react-hook-form";
import { useCreateOrderMutation } from "store/api/ordersApi";
import getEuro from "utils/getEuro";
import { SelectSendOption } from "containers/Refunds/RefundsPage";
import { normalizeAttrName } from "utils/normalizeAttr";
import { useUpdateBillingMutation } from "store/api/customersApi";
import PaymentForm from "components/PaymentForm";
import toast from "react-hot-toast";
import {
  StockWarning,
  WarningIcon,
} from "containers/ProductDetailPage/GroupedDetailPage";
import { Dialog, DialogContent } from "@mui/material";
import { _renderLoading } from "components/SectionSliderProductCard";
import { orderBy } from "lodash";
import { SEND_PRICE } from "constants/data";
import Students from "./Students";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices";
import { post } from "utils/post";
import Checkbox from "shared/Checkbox/Checkbox";
import { TruckIcon } from "@heroicons/react/24/outline";

type FormValues = {
  first_name: string;
  last_name: string;
  address_1: string;
  city: string;
  state: string;
  postcode: number;
  country: string;
  email: string;
  phone: string;
};

const CheckoutPage = () => {
  const [buy, res] = useCreateOrderMutation();
  const [update, resUpdate] = useUpdateBillingMutation();
  const {
    items,
    total,
    resetCart,
    addItemToCart,
    removeItemFromCart,
    clearItemFromCart,
  } = useCart();
  const user = useUser();
  const [students, setStudents] = useState<Student[]>([]);
  const [differentBillAddress, setDifferentBillAddress] = useState(false)
  const [student, setStudent] = useState<number>(0);
  const navigate = useNavigate();
  const college = useCollege();
  const dispatch = useDispatch();
  const [to, setTo] = useState("colegio");
  const contactInfoRef = useRef<HTMLDivElement>(null);
  const shippingAddressRef = useRef<HTMLDivElement>(null);
  const studentsRef = useRef<HTMLDivElement>(null);
  const billAddressRef = useRef<HTMLDivElement>(null)
  const limitSellPrice = Number(college.limit_sell_price || 0)
  // const alumno = getStudent(user);
  // const totalItems = items.reduce((acc, curr) => {
  //   acc += curr.quantity;
  //   return acc;
  // }, 0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
  } = useForm<FormValues>({
    defaultValues: useMemo(() => {
      return user || {};
    }, [user]),
  });
  useEffect(() => {
    setStudents(user?.students || []);
  }, [user]);
  useEffect(() => {
    if (res.isError) {
      toast.error((res.error as WooCommerceError).data.message, {
        duration: 10000,
      });
    } else if (res.isSuccess) {
      resetCart();
      if(!res?.data?.data?.redsys){
        navigate("/ok");
      }else{
        const redsys = res?.data?.data?.redsys
        post(redsys?.form_action, redsys)
      }
      // window.location.href = res.data.payment_url.replace(
      //   /pay_for_order=true&/g,
      //   ""
      // );
    }
  }, [res]);
  const handleScrollToEl = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
      }, 80);
    }
  };
  const isPending = res.isLoading;
  const hasFamily = user?.big_family;
  const familyDiscount = college.family_discount
    ? total * (college.family_discount / 100)
    : 0;
  const isFreeSend = useMemo(() => {
    return college?.free_cart_amount && total >= college?.free_cart_amount;
  }, [college, total]);

  const sentPrice = isFreeSend ? 0 : to === "colegio" ? 0 : SEND_PRICE;

  async function onSubmit(data: any) {
    const newOrder = {
      send_type: to === "colegio" ? SEND_TYPE.COLLEGE : SEND_TYPE.DIRECTION,
      is_send_free: Boolean(isFreeSend),
      send_price: sentPrice,
      discount: hasFamily ? college.family_discount : 0,
      school_id: college.id,
      student,
      is_same_address:to === 'colegio' || !differentBillAddress,
      products: items.map((it) => ({
        amount: it.quantity,
        product_id: it.id,
        parent_id: it.parent_id,
        pivot_parent_id: it.pivot_parent_id,
      })),
    };
    delete data.schools;
    update({
      ...data,
      students: students.map((it) => {
        if (typeof it.id === "string") {
          delete it.id;
        }
        return it;
      }),
    }).then((res) => {
      dispatch(setUser(res.data.data));
      buy(newOrder);
    });
  }
  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "Students" | "BillAddress"
  >("Students");

  const renderProduct = (item: CartItem, index: number) => {
    const {
      parent_id: id,
      image,
      price,
      name,
      itemTotal,
      attributes,
      quantity,
      stock_quantity,
      limit_store,
      maxCartAmount,
    } = item;

    return (
      <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
        <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
          <Link
            to={`/product-detail?id=${id}`}
            className="absolute inset-0"
          ></Link>
        </div>

        <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="text-base font-semibold">
                  <Link to={`/product-detail?id=${item.id}`}>{name}</Link>
                </h3>
                <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                  {orderBy(Object.keys(attributes)).map(
                    (it: string, idx: number) => {
                      return (
                        <>
                          <span className="capitalize">
                            {normalizeAttrName(it)}: {attributes[it]}
                          </span>
                          {Object.values(attributes).length - 1 !== idx && (
                            <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                          )}
                        </>
                      );
                    }
                  )}
                </div>
                <div>
                  {stock_quantity < quantity && (
                    <p className="text-red-400 text-xs">
                      En fabricación, el producto se enviará cuando las
                      cantidades vuelvan a estar disponibles.
                    </p>
                  )}
                  {limit_store && (
                    <p className="text-red-400 text-xs">
                      Este producto está limitado a una compra por estudiante.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-1 sm:flex justify-end">
                <Prices price={itemTotal} className="mt-0.5" />
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber
                onIncrement={() => addItemToCart(item, 1)}
                onDecrement={() => removeItemFromCart(item.id)}
                defaultValue={quantity}
                className="relative z-10"
                max={maxCartAmount}
              />
            </div>

            <a
              href="##"
              className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
              onClick={() => clearItemFromCart(item.id)}
            >
              <span>Eliminar</span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderLeft = () => {
    return (
      <div className="space-y-8">
        <div ref={contactInfoRef} className="scroll-mt-24">
          <ContactInfo
            students={students}
            setStudents={setStudents}
            register={register}
            errors={errors}
            selectedStudentIdx={student}
            setSelectedStudentIdx={setStudent}
            isActive={tabActive === "ContactInfo"}
            onOpenActive={() => {
              setTabActive(
                tabActive === "ContactInfo" ? undefined : "ContactInfo"
              );
              handleScrollToEl(contactInfoRef);
            }}
            onCloseActive={() => {
              setTabActive("Students");
              handleScrollToEl(studentsRef);
            }}
            getValues={getValues}
            setValue={setValue}
          />
        </div>

        <div ref={studentsRef} className="scroll-mt-24">
          <Students
            isActive
            student={student}
            setStudent={setStudent}
            setStudents={setStudents}
            students={students}
          />
        </div>

        <div ref={shippingAddressRef} className="scroll-mt-24">
          <ShippingAddress
            title={'Dirección de facturación'}
            register={register}
            errors={errors}
            isActive={tabActive === "ShippingAddress"}
            onOpenActive={() => {
              setTabActive(
                tabActive === "ShippingAddress" ? undefined : "ShippingAddress"
              );
              handleScrollToEl(shippingAddressRef);
            }}
            onCloseActive={() => {
              setTabActive("Students");
              handleScrollToEl(shippingAddressRef);
            }}
          />
        </div>

        {to !== 'colegio' && <div>
          <Checkbox onChange={()=>setDifferentBillAddress(prev=>!prev)} name=""  checked={!differentBillAddress} label="La dirección de facturación y envío es la misma"></Checkbox>
        </div>}

        {differentBillAddress && <div ref={billAddressRef} className="scroll-mt-24">
          <ShippingAddress
            icon={<TruckIcon className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5" />}
            title={'Dirección de envío'}
            register={register}
            errors={errors}
            prefixFields="shipping_"
            isActive={tabActive === "BillAddress"}
            onOpenActive={() => {
              setTabActive(
                tabActive === "BillAddress" ? undefined : "BillAddress"
              );
              handleScrollToEl(shippingAddressRef);
            }}
            onCloseActive={() => {
              setTabActive("Students");
              handleScrollToEl(studentsRef);
            }}
          />
        </div>}

        
        {/* <div id="PaymentMethod" className="scroll-mt-24">
          <PaymentMethod
            isActive={tabActive === "PaymentMethod"}
            onOpenActive={() => {
              setTabActive("PaymentMethod");
              handleScrollToEl("PaymentMethod");
            }}
            onCloseActive={() => setTabActive("PaymentMethod")}
          />
        </div> */}
        <SelectSendOption
          collegeLabel={`Envío al colegio GRATIS`}
          addressSendLabel={`Envío a la dirección de facturación ${
            isFreeSend ? "GRATIS" : getEuro(SEND_PRICE)
          }`}
          setSend={(send) => {
            setTo(send);
          }}
          send={to}
        />
      </div>
    );
  };

  return (
    <div className="nc-CheckoutPage">
      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Datos del pago
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Inicio
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Datos del pago</span>
          </div>
        </div>
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">{renderLeft()}</div>

          <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

          <div className="w-full lg:w-[36%] ">
            <h3 className="text-lg font-semibold">Pedido</h3>
            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
              {items.map(renderProduct)}
            </div>

            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
              {/* <div>
                <Label className="text-sm">Discount code</Label>
                <div className="flex mt-1.5">
                  <Input sizeClass="h-10 px-4 py-3" className="flex-1" />
                  <button className="text-neutral-700 dark:text-neutral-200 border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-100 rounded-2xl px-4 ml-3 font-medium text-sm bg-neutral-200/70 dark:bg-neutral-700 dark:hover:bg-neutral-800 w-24 flex justify-center items-center transition-colors">
                    Apply
                  </button>
                </div>
              </div> */}

              <div className="mt-4 flex justify-between py-2.5">
                <span>Precio</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {getEuro(total / 1.21)}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>IVA</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {getEuro(total - total / 1.21)}
                </span>
              </div>
              <div className="flex justify-between py-2.5">
                <span>Envío</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {sentPrice === 0 ? "GRATIS" : getEuro(sentPrice)}
                </span>
              </div>
              {hasFamily && (
                <div className="flex justify-between py-2.5">
                  <span>Descuento</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    {getEuro(-familyDiscount)}
                  </span>
                </div>
              )}
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                <span>Subtotal</span>
                <span>{getEuro(total)}</span>
              </div>
              <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                <span>Total</span>
                <span>
                  {getEuro(
                    total + sentPrice - (hasFamily ? familyDiscount || 0 : 0)
                  )}
                </span>
              </div>
            </div>
            {/* {res.isSuccess && res.data.data.redsys && (
              <PaymentForm paymentParams={res.data.data.redsys} />
            )} */}
            <ButtonPrimary
              disabled={items.length === 0 || !students?.[student] || total < limitSellPrice }
              onClick={handleSubmit(onSubmit)}
              loading={res.isSuccess || res.isLoading || resUpdate.isLoading}
              type="submit"
              className="mt-8 w-full"
            >
              Realizar Pago
            </ButtonPrimary>
            {items?.some((it) => it.quantity > it.stock_quantity) && (
              <StockWarning />
            )}
            {/* <Dialog open={res.isSuccess}>
              <DialogContent>
                <div className="p-8 flex flex-col gap-2 items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-10 w-10 mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="3"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <h5>Redireccionando...</h5>
                </div>
              </DialogContent>
            </Dialog> */}
            {
              total < limitSellPrice && (
                <div className="mt-5 text-sm text-red-500 dark:text-red-400 flex items-center justify-center">
                  <p className="block relative pl-5">
                    <WarningIcon />
                    El precio minimo de compra es de { getEuro(limitSellPrice) }
                  </p>
                </div>
              )
            }
            {!students?.[student] && (
              <div className="mt-5 text-sm text-red-500 dark:text-red-400 flex items-center justify-center">
                <p className="block relative pl-5">
                  <WarningIcon />
                  Debe agregar al menos un estudiante
                </p>
              </div>
            )}
            {Object.values(errors).length !== 0 && (
              <div className="mt-4 text-red-500 flex justify-center">
                <svg
                  className="w-4 h-4 mr-2 mt-[2px]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8V13"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9945 16H12.0035"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Rellene todos sus datos
              </div>
            )}
            {items.length === 0 && !res.isSuccess && (
              <div className="mt-5 text-sm text-red-500 dark:text-red-400 flex items-center justify-center">
                <p className="block relative pl-5">
                  <svg
                    className="w-4 h-4 absolute -left-1 top-0.5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 8V13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.9945 16H12.0035"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Agregue algún producto para realizar la compra
                </p>
              </div>
            )}
          </div>
        </div>
        {/* </form> */}
      </main>
    </div>
  );
};

export default CheckoutPage;
