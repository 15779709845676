import React, { useRef, useState } from 'react'
import CommonLayout from './CommonLayout'
import Input from 'shared/Input/Input'
import Label from 'components/Label/Label'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import ConfirmDialog from 'components/ConfirmDialog'
import { DELETE_ACCOUNT_KEY } from 'constants/data'
import { useDeleteAccountMutation } from 'store/api/baseApi'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { setUser } from 'store/slices'
import { useNavigate } from 'react-router-dom'

const AccountRemove = () => {
    const [isConfirm, setIsConfirm] = useState(false)
    const [remove,{isLoading}] = useDeleteAccountMutation()
    const inputRef = useRef<HTMLInputElement>(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [error,setError] = useState<string | undefined>()
    const handleConfirm = () => {
        if(inputRef?.current?.value === DELETE_ACCOUNT_KEY){
            setIsConfirm(true)
        }else{
            setError("Palabra clave incorrecta")
        }
    }
  return (
    <div>
        <CommonLayout>
            <div className="flex flex-col gap-4">
                {/* HEADING */}
                <h2 className="text-2xl pb-8 sm:text-3xl font-semibold">
                Eliminar cuenta
                </h2>
                <h5>Al proceder con la eliminación de su cuenta, perderá acceso permanente a todos sus datos y no podrá volver a iniciar sesión en nuestro sitio.</h5>
                <h5>Para confirmar que realmente desea eliminar su cuenta, escriba la palabra clave: <i><b>"{DELETE_ACCOUNT_KEY}".</b></i></h5>
                <div>
                    <Input  onChange={()=>setError(undefined)} error={error} ref={inputRef} placeholder='Escriba aquí la palabra clave para confirmar'/>
                </div>
                <ButtonPrimary onClick={handleConfirm} className='mb-4 mr-auto'>Eliminar</ButtonPrimary>
                <ConfirmDialog
                    open={isConfirm}
                    hide={()=>setIsConfirm(false)}
                    title='Eliminar cuenta'
                    isLoading={isLoading}
                    desc='¿Seguro que desea eliminar su cuenta?'
                    onConfirm={async ()=>{
                        const res = await remove()
                        if(!res.error){
                            localStorage.removeItem("accessToken")
                            dispatch(setUser(undefined));
                            navigate('/');
                            toast.success("Cuenta eliminada correctamente")
                        }else{
                            toast.error(res?.error?.data?.message || "Ha ocurrido un error")
                        }
                    }}
                />
            </div>
        </CommonLayout>
    </div>
  )
}

export default AccountRemove