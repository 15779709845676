import { colors } from "utils/css-colors";

export const ProductAttributes = ({
  className = "mb-4",
  title,
  attributes,
  active,
  onClick,
}) => {
  return (
    <div className={className}>
      <h3 className={`text-base md:text-lg font-semibold mb-2.5 capitalize`}>
        {title}
      </h3>
      <ul className="flex flex-wrap colors ltr:-mr-3 rtl:-ml-3">
        {attributes?.map((it) => (
          <li
            key={`${it}`}
            className={`cursor-pointer rounded border h-9 md:h-11 p-1 mb-2 md:mb-3 mr-2 md:mr-3 flex justify-center items-center text-xs md:text-sm uppercase font-semibold transition duration-200 ease-in-out hover:border-[#000] ${
              it === active ? `bg-gray-900 text-white border-gray-900` : `border-gray-300`
            }`}
            onClick={() => onClick({ [title]: it })}
            style={{minWidth:40}}
          >
            {title.includes("color") && colors[it.toLowerCase()] ? (
              <span
                className="block w-full h-full rounded"
                style={{ backgroundColor: colors[it.toLowerCase()] }}
              />
            ) : (
              it
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
