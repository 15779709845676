import React from 'react'
import Select from 'shared/Select/Select'
import { useGetCursosQuery } from 'store/api/cursoApi'
import { _renderLoading } from './SectionSliderProductCard'
import { useCollege } from 'store/utils/hooks'

const SelectCurso = ({onChange, defaultValue}:{onChange:(curso:number)=>void, defaultValue?:number, errors:any}) => {
  const college = useCollege()
  if(!college){
    return _renderLoading()
  }
  return (
    <>
        <Select defaultValue={defaultValue} name='curso' onChange={(e)=>{
            onChange && onChange(Number(e.target.value))
        }}>
            {
                college.courses?.map((it:any)=><option key={it?.id} value={it?.id}>
                    {it?.name}
                </option>)
            }
        </Select>
        {/* {errors.curso && <span className='text-red-500 text-sm'>{errors.curso?.message}</span>} */}
    </>
  )
}

export default SelectCurso