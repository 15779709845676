import WidgetHeading1 from 'containers/BlogPage/WidgetHeading1'
import { RichTextFormat } from 'containers/ProductDetailPage/AccordionInfo'
import React from 'react'
import Heading from 'shared/Heading/Heading'
import { useCollege } from 'store/utils/hooks'
import parse from 'html-react-parser';

export default () => {
    const college = useCollege()
  return (
    <div className='container my-16'>
        <Heading desc={college?.name}>
            FAQS
        </Heading>
        <RichTextFormat>
          {college?.faqs ? parse(college?.faqs) : "Aún no hay nada que mostrar"}
        </RichTextFormat>
    </div>
  )
}
