import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLoginMutation } from "store/api/customersApi";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices";
import { Resolver, useForm } from "react-hook-form";
import { User, WooCommerceError } from "data/data";
import { saveSessionsIds } from "utils/session";
import { useCollege } from "store/utils/hooks";
import PrettyDivider from "shared/PrettyDivider";
import VerifyCode from "containers/VerifyCode";
import { useVerifyAccountMutation } from "store/api/baseApi";
import toast from "react-hot-toast";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

type FormValues = {
  email: string;
  password: string;
};



const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [login, res] = useLoginMutation();
  const [verify, resVerify] = useVerifyAccountMutation();
  const isPending = res.isLoading;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const college = useCollege();
  const [tempUser, setTempUser] = useState<User | undefined>();
  const [idx, setIdx] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>();

  async function onSubmit(data:any) {
    const { email:username, password } = data as FormValues
    if(idx===0){
      const res = await login({
        username,
        password,
      });
      if (res.error) {
        setError("password", { message: (res.error as WooCommerceError).data.message });
      } else if(res.data.data.user.is_verified) {
        dispatch(setUser(res.data.data.user));
        localStorage.setItem('accessToken', res.data.data.accessToken)
        navigate("/");
      }else{
        toast.error(res.data.message)
        setIdx(1)
        setTempUser(res.data.data.user)
      }
    }else{
      const res = await verify({
        pin:data.code,
        email:tempUser.email
      })
      if(res.error){
        toast.error(res.error.data.message)
      }else{
        toast.success(res.data.message)
        dispatch(setUser(res.data.data.user))
        localStorage.setItem("accessToken", res.data.data.accessToken)
        navigate('/')
      }
    }
  }

  const renderLogin = () => {
    return <>
      <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}
            noValidate>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email o nombre de usuario
              </span>
              <Input
                type="email"
                placeholder="ejemplo@ejemplo.com"
                className="mt-1"
                error={errors.email?.message}
                {
                  ...register('email',{
                    required:'Escriba el correo o el nombre de usuario'
                  })
                }
              />
            </label>
            <label className="block">
              <Input placeholder="contraseña" error={errors.password?.message} type="password" className="mt-1" {
                  ...register('password',{
                    required:'La contraseña es requerida'
                  })
                }/>
            </label>
            <ButtonPrimary type="submit" loading={res.isLoading}>Continuar</ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <Link className="font-semibold hover:underline" to="/forgot-pass">
            ¿Olvidaste la contraseña?
            </Link>
          </span>
          <PrettyDivider/>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            ¿Primera vez? {` `}
            <Link className="font-semibold hover:underline" to="/signup">
              Crea una cuenta
            </Link>
          </span>
    </>
  }
  const renderVerify = () => <VerifyCode
    register={register}
    handleSubmit={handleSubmit}
    onSubmit={onSubmit}
    errors={errors}
    isLoading={resVerify.isLoading}
    email={tempUser?.email}
  />
  const forms = [renderLogin, renderVerify]
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Iniciar sesión 
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          {forms[idx]()}
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
