export enum ORDER_STATUS {
  PENDING = 'pending',
  WAITING = 'waiting',
  PROCESSING = 'processing',
  PARTIAL_SEND = 'partial_send',
  SENDED = 'sended',
  DEVOLUTION_REQUEST = 'devolution_request',
  REFUNDED = 'refunded',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  FULL_COMPLETE = 'full_complete'
}

export type OrderStatus = `${ORDER_STATUS}`

const orderStatuses: {
  [key in ORDER_STATUS]: {
    label: string;
    color: string;
  };
} = {
  [ORDER_STATUS.PENDING]: {
    label: "Pago pendiente",
    color: "#ffc771",
  },
  [ORDER_STATUS.PROCESSING]: {
    label: "Procesando",
    color: "#0000ff",
  },
  [ORDER_STATUS.COMPLETE]: {
    label: "Entregado",
    color: "#0ea5e9",
  },
  [ORDER_STATUS.CANCELLED]: {
    label: "Cancelado",
    color: "#d9534f",
  },
  [ORDER_STATUS.REFUNDED]: {
    label: "Reembolsado",
    color: "#800080",
  },
  [ORDER_STATUS.WAITING]: {
    label: "En preparación",
    color: "#5bc0de",
  },
  [null]: {
    label: "En espera",
    color: "#5bc0de",
  },
  [ORDER_STATUS.PARTIAL_SEND]: {
    label: "Envío parcial",
    color: "#f97316",
  },
  [ORDER_STATUS.SENDED]: {
    label: "Enviado",
    color: "#0275d8",
  },
  [ORDER_STATUS.DEVOLUTION_REQUEST]: {
    label: 'Solicitud de devolución',
    color: '#c21170',
  },
  [ORDER_STATUS.FULL_COMPLETE]: {
    label: 'Completado',
    color: '#5cb85c',
  }
};

export const getOrderStatus = (status: OrderStatus) => orderStatuses[status];
