import React from 'react'
import Select from 'shared/Select/Select'

const reassons = [
    "He pedido un producto equivocado (talla o prenda).",
    "He recibido un producto erróneo.",
    "Producto defectuoso o dañado.",
    "Otra razón.",
]

const SelectReason = ({onChange}:{onChange:(reason:string)=>void}) => {
  return (
    <Select name='reason_option' onChange={(e)=>{
        const value = e.target.value
        onChange(value)
    }}>
        {
            reassons.map((it)=><option key={it} value={it}>
                {it}
            </option>)
        }
    </Select>
  )
}

export default SelectReason