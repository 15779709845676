import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import rightImg from "images/about-hero-right.png";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Svg from 'images/svg-right-2.png'

export interface SectionHeroProps {
  className?: string;
  title?: ReactNode;
  subtitle?: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  title = "Discover, collect, and sell extraordinary NFTs ",
  subtitle = "Discover the most outstanding NTFs in all topics of life. Creative your NTFs and sell them",
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} pb-8`}
      data-nc-id="SectionHero"
    >
      <div className="w-screen max-w-full justify-center text-center space-y-5 lg:space-y-7">
          <img src={Svg} className="md:max-w-[500px] mx-auto mb-8" />
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {title}
          </h2>
          <span className="block mx-auto text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 max-w-lg">
            {subtitle}
          </span>
          <ButtonPrimary href="/account-my-order">
              <span className="">Ver Pedidos</span>
              <MagnifyingGlassIcon className="w-5 h-5 ml-2.5" />
            </ButtonPrimary>
        </div>
    </div>
  );
};

export default SectionHero;
