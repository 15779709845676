import React from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useCollege } from "store/utils/hooks";

function App() {
  const college = useCollege()
  return (
    <HelmetProvider>
      <Helmet>
        <title>{college?.name}</title>
        <link rel="icon" href={college?.logo}/>
      </Helmet>

      {/* MAIN APP */}
      <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
          <MyRouter />
      </div>
    </HelmetProvider>
  );
}

export default App;
