import {createSlice} from '@reduxjs/toolkit';

export const collegeSlice = createSlice ({
  name: 'college',
  initialState: {
    college: undefined,
  },
  reducers: {
    setCollege: (state, action) => {
      state.college = action.payload;
    },
  },
});

export const {setCollege} = collegeSlice.actions;
