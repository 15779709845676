import React, { useEffect, useMemo, useRef, useState } from "react";
import CommonLayout from "./CommonLayout";
import ShippingAddress from "containers/PageCheckout/ShippingAddress";
import { useForm } from "react-hook-form";
import { useUser } from "store/utils/hooks";
import { TruckIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { useUpdateBillingMutation } from "store/api/customersApi";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import toast from "react-hot-toast";
import { setUser } from "store/slices";

const AccountAddresses = ({ className = "" }: { className?: string }) => {
  const user = useUser();
  const [update, res] = useUpdateBillingMutation();
  const dispatch = useDispatch();
  const shippingAddressRef = useRef(null);
  const billAddressRef = useRef(null);
  const [tabActive, setTabActive] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useForm<any>({
    defaultValues: useMemo(() => {
      return user;
    }, [user]),
  });

  useEffect(() => {
    if (res.isError) {
      toast.error(res.error.data.message)
    } else if (res.isSuccess) {
      console.log({res})
      dispatch(setUser(res.data.data));
      toast.success("Usuario actualizado");
    }
  }, [res]);

  const handleScrollToEl = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
      }, 80);
    }
  };
  function onSubmit(data: any) {
    delete data.schools;
    delete data.students;
    update({ ...user, ...{schools:undefined, students:undefined}, ...data });
  }
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">Dirección</h2>
          <div className="">
            <form onSubmit={handleSubmit(onSubmit)} noValidate className="xl:grid xl:gap-4 xl:grid-cols-2">
              <div ref={shippingAddressRef} className="scroll-mt-24">
                <ShippingAddress
                  title={"Dirección de facturación"}
                  register={register}
                  errors={errors}
                  isActive={tabActive === "ShippingAddress"}
                  onOpenActive={() => {
                    setTabActive(
                      tabActive === "ShippingAddress"
                        ? undefined
                        : "ShippingAddress"
                    );
                    handleScrollToEl(shippingAddressRef);
                  }}
                  saveButtonLabel="Aceptar"
                  onCloseActive={() => {
                    setTabActive(null);
                    handleScrollToEl(billAddressRef);
                  }}
                />
              </div>
              <div ref={billAddressRef} className="scroll-mt-24 max-xl:mt-8 mb-4">
                <ShippingAddress
                  icon={
                    <TruckIcon className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5" />
                  }
                  title={"Dirección de envío"}
                  register={register}
                  errors={errors}
                  saveButtonLabel="Aceptar"
                  prefixFields="shipping_"
                  isActive={tabActive === "BillAddress"}
                  onOpenActive={() => {
                    setTabActive(
                      tabActive === "BillAddress" ? undefined : "BillAddress"
                    );
                    handleScrollToEl(shippingAddressRef);
                  }}
                  onCloseActive={() => {
                    setTabActive(null);
                    handleScrollToEl(shippingAddressRef);
                  }}
                />
              </div>
              {/* ---- */}
              <div className="pt-2">
                <ButtonPrimary
                  loading={res.isLoading}
                  type="submit"
                  className="col-span-2"
                >
                  Guardar
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountAddresses;
