import SectionHero3 from "components/SectionHero/SectionHero3";
import image from "images/promo3.png";

export default () => {
  return (
    <main className="container py-10">
      <SectionHero3
        title="¡Felicidades!"
        subtitle="Usted ha realizado exitosamente su pedido"
        img={image}
        bg={'#edf7ea'}
      />
    </main>
  );
};
