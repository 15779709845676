import {TEN_YEARS} from 'constants/api';
import {ORDERS_TAG, baseApi} from './baseApi';
import { Order } from 'data/types';

const BASE_URL = 'orders';
const HISTORICAL_REFUNDS = "HISTORICAL_REFUNDS"
const ordersApi = baseApi.injectEndpoints ({
  endpoints: builder => ({
    createOrder: builder.mutation ({
      query: body => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ORDERS_TAG],
    }),
    getOrder:builder.query({
      query: (id)=>`${BASE_URL}/${id}`,
      keepUnusedDataFor: TEN_YEARS,
      providesTags: [ORDERS_TAG],
      transformResponse: (res:{data:Order}) => res.data
    }),
    getOrders: builder.query ({
      query: ({userId, ...params}) => ({
        url:BASE_URL,
        method:"GET",
        params:{
          user_id:userId,
          ...params
        }
      }),
      keepUnusedDataFor: TEN_YEARS,
      providesTags: [ORDERS_TAG],
      transformResponse: (res:{
       data:{data:Order[]}
      }) => res.data
    }),
    updateOrder: builder.mutation ({
      query: ({id, ...patch}) => ({
        url: BASE_URL + `/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: [ORDERS_TAG],
    }),
    createRefund: builder.mutation ({
      query: ({order, body}) => ({
        method: 'POST',
        url: `${BASE_URL}/devolution-request/${order}`,
        body,
      }),
      invalidatesTags:[ORDERS_TAG,HISTORICAL_REFUNDS]
    }),
    confirmRedsys: builder.mutation({
      query:(body)=>({
        method:'POST',
        url:`redsys/webhook`,
        body,
      })
    }),
    retryPayment: builder.mutation({
      query:(id)=>({
        method:'POST',
        url:BASE_URL+'/retry-order/'+id,
      })
    }),
    getHistoricalRefunded: builder.query({
        query:(id)=>`${BASE_URL}/devolution-request/${id}?per_page=100`,
        transformResponse:res=>res.data,
        providesTags:[HISTORICAL_REFUNDS]
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrdersQuery,
  useCreateRefundMutation,
  useUpdateOrderMutation,
  useConfirmRedsysMutation,
  useRetryPaymentMutation,
  useGetHistoricalRefundedQuery,
  useGetOrderQuery,
} = ordersApi;
