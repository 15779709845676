import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useForm } from "react-hook-form";
import { useUpdatePasswordMutation } from "store/api/customersApi";
import toast, { Toaster } from "react-hot-toast";
import CheckPassword from "components/CheckPassword";

const AccountPass = () => {
  const [update, res] = useUpdatePasswordMutation()
  const onSubmit = async (data) => {
    const res = await update(data)
    if(res.error){
      toast.error(res?.error?.data?.message || "Ha ocurrido un error")
    }else{
      toast.success(res.data.message)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  const password = watch('password')

  return (
    <div>
      <CommonLayout>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-10 sm:space-y-12">
            {/* HEADING */}
            <h2 className="text-2xl sm:text-3xl font-semibold">
              Cambia tu contraseña
            </h2>
            <div className=" max-w-xl space-y-6">
              <div>
                <Label>Contraseña actual</Label>
                <Input error={errors?.pass?.message} type="password" className="mt-1.5" {
                  ...register("pass",{
                    required:'La contrase\u00f1a es requerida'
                  })
                }/>
              </div>
              <div>
                <Label>Nueva contraseña</Label>
                <Input error={errors?.password?.message} type="password" className="mt-1.5" {
                  ...register("password",{
                    required:'La nueva contrase\u00f1a es requerida'
                  })
                }/>
              </div>
              <CheckPassword password={password || ""}/>
              <div>
                <Label>Confirmar contraseña</Label>
                <Input error={errors?.password_confirmation?.message} {
                  ...register("password_confirmation",{
                    required: `${"Confirme su contrase\u00f1a"}`,
                    validate: (value, fromValues) =>
                      fromValues.password === value ||
                      "Las contrase\u00f1as son diferentes",
                  })
                } type="password" className="mt-1.5" />
              </div>
              <div className="pt-2">
                <ButtonPrimary loading={res.isLoading}>Cambiar</ButtonPrimary>
              </div>
            </div>
          </div>
        </form>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
